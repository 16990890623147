@import '../../sass/variables';

@function particles($max) {
	$val: 0px 0px $color-particle;

	@for $i from 1 through $max {
		$val: #{$val},
		random($spacing)+px random($spacing)+px $color-particle;
	}

	@return $val;
}

@mixin particles($max) {
	box-shadow: particles($max);
}

.animation-wrapper {
	position: relative;
}

.particles {
	opacity: 0.3;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;

	@media (max-width: 700px) {
		display: none;
	}
}

.particle,
.particle:after {
	background: transparent;
}

.particle:after {
	position: absolute;
	content: "";
	top: $spacing;
}

.particle-1 {
	animation: animParticle $time-1 linear infinite;
	@include particles(400);
	height: 1px;
	width: 1px;
}

.particle-1:after {
	@include particles(400);
	height: 1px;
	width: 1px;
}

.particle-2 {
	animation: animParticle $time-2 linear infinite;
	@include particles(100);
	height: 2px;
	width: 2px;
}

.particle-2:after {
	@include particles(100);
	height: 2px;
	width: 2px;
}

.particle-3 {
	animation: animParticle $time-3 linear infinite;
	@include particles(30);
	height: 3px;
	width: 3px;
}

.particle-3:after {
	@include particles(30);
	height: 3px;
	width: 3px;
}

.particle-4 {
	animation: animParticle $time-4 linear infinite;
	@include particles(300);
	height: 1px;
	width: 1px;
}

.particle-4:after {
	@include particles(300);
	height: 1px;
	width: 1px;
}

@keyframes animParticle {
	from {
		transform: translateY(0px);
	}

	to {
		transform: translateY($spacing * -1);
	}
}