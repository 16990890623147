$color-primary: #00fbff;
$color-primary-hover: #31fcff;
$color-background: #191a1b;
$font-primary: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
$color-particle: #fff;
$transition-time: 0.5s;
$spacing: 2560px;
$time-1: 60s;
$time-2: 120s;
$time-3: 180s;
$time-4: 600s;