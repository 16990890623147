@import '../../sass/variables';

#contact {
	padding: 20px 0px;
	background: #131415;
	position: relative;
	font-weight: 300;
	color: rgba(255, 255, 255, 0.3);
	font-size: 14px;
	text-align: center;

	.icons {
		font-size: 1.5rem;
		margin-bottom: 10px;

		.icon {
			display: inline-block;
			padding: 0px 8px;

			a {
				color: rgba(255, 255, 255, 0.3);
				-webkit-transition: color $transition-time ease-in-out;
				-moz-transition: color $transition-time ease-in-out;
				-o-transition: color $transition-time ease-in-out;
				transition: color $transition-time ease-in-out;

				&:hover {
					color: $color-primary-hover;
				}
			}
		}
	}
}